@media screen and (max-width:1440px) {
	/*.lp_menu { font-size:15px;}*/
    .service_tabs.nav-tabs .nav-link {
        max-width: 250px;
        margin: 20px;
        padding: 10rem 1.5rem 1.5rem;
    }
    .hiw_card { max-width: 260px;}
    .mso_info {
        max-width: 500px;
        margin: auto;
        width: 50%;
    }
    .mso_img {
        width: 50%;
    }
    .mso_img img{
        max-width: 100%;
    }
    .hero_text{ width: 55%;}
    .hero_img {
        width: 45%;
    }
    .hero_text h2 { font-size: 3rem;}
    .hero_text p { font-size: 1.1rem;}
    .page_title h2 { font-size: 2rem;}
    .page_title span {font-size: 0.85rem;}
    .lps_intro h4 { font-size: 1.5rem;}
    .hiw_card h4 {font-size: 1.3rem; }
    .te_main_lpage p {line-height: 24px;}
    .ready_sec h2 {font-size: 2.5rem;}
    .foot_logo {
        max-width: 200px;
    }
    .btn{ font-size: 0.85rem;}
    .ownr_bio .ob_info {
        /*max-width: 500px;*/max-width: 100%; padding:30px;
    }
}
@media screen and ( max-width:1366px) and (min-device-aspect-ratio: 4/3){
	body{font-size:14px;}
	
    .te_main_lpage .container,.container{max-width: 1200px;}
    /*.reg_form{
		max-width:360px;
	}*/
	 .reg_form img{ max-width: 12%;}
	.te_logo img{ max-width: 40px;}
    .hero_text h2 {
        font-size: 2.5rem;
    }
    .sub_head{ font-size: 1.25rem;}
    .hero_text p {
        font-size: 1rem;
    }
  
    .page_title h2 {
        font-size: 1.5rem;
    }
    .page_title span {
        font-size: 0.75rem;
    }
    .service_tabs.nav-tabs .nav-link{ font-size: 1rem;}
    .lps_intro h4 {
        font-size: 1.15rem;
    }
    .te_main_lpage p {
        line-height: 24px;
        font-size: 0.9rem;
    }
    .hiw_card h4 {
        font-size: 1rem;
    }
    .ob_info h2{
        font-size: 1.5rem;
    }
    .ob_info strong {
        font-size: .75rem;
    }
    .certi_list li span.state_name {
        font-size: 1rem;
    }
    .bs_box h4 {
        font-size: 1rem;
    }
    .fea_desc h5 {
        font-size: 1rem;
    }
	.btns_grp .gold_btn {
		font-size: 13px;
	}
	.h5, h5{ font-size:1.10rem;}
	
}
@media screen and ( max-width:1200px){
    .hero_wrap {
        max-height: 700px;}
        .ownr_bio .ob_info {
            max-width: 450px;padding: 25px;
        }
        .certi_list li {
            margin-bottom: 25px;
        }  
        .ownr_bio,
        .ob_img {
            height: 100%;
        }
        .ob_img img {
            max-height: 900px;
            object-fit: cover;
            max-width: 400px;
            height: 100%;
        }
        .hero_wrap::before{top: auto; bottom: 5%;}
       
}
@media screen and (max-width:1183px){
    .hiw_card:nth-child(3)::after {
        right: -30%;
        bottom: 0;
        top: auto;
        transform: rotate(90deg);
      }
    .hiw_card:nth-child(4){order: 5;} 
    .hiw_card:nth-child(4)::after {
        left: -30%;
        transform: scaleY(1) rotate(180deg);
        bottom: 80%;
      }
      .hiw_list .hiw_card:nth-child(5) {
        flex-direction: column;
        max-width: 260px;
        min-height: 200px;
        text-align: center;
        justify-content: space-around;
        align-items: center;
      }
}
@media screen and ( max-width:992px){
.hero_wrap {max-height: 600px;}
.mso_wrap {flex-direction: column;}
.mso_wrap::after,.mso_wrap::before{ display: none;}
.mso_info{ padding: 25px; max-width: 800px;width: 100%;height: 100%;border-radius: 20px;background-color: rgba(226, 179, 39,0.2);}
.mso_img {width: 100%;max-width: 500px;margin: -50px auto;}
.lp_menu {background-color: white;}
    .navbar-nav{align-items: center;}
    .hd_btn_grp{margin: 1rem auto;}
    .hero_wrap::before{display: none;}
    .hero_text p { font-size: 0.875rem;}
}
@media screen and ( max-width:910px){
    .hiw_card {
        max-width: 550px;
        flex-direction: row;
        align-items: center;
        min-height: 150px;
      }
      .hiw_card .hiw_img {
        width: 150px; flex: 1;
      }
      .hiw_card div:last-child{flex: 2;}
      .hiw_list .hiw_card:nth-child(5) {
        flex-direction: row;
        max-width: 550px;
        min-height: 150px;
        text-align: center;
        justify-content: space-around;
        align-items: center;
      }
      .hiw_card:nth-child(4) {
        order: 4;
      }
      .hiw_card:nth-child(5) {
        order: 5;
      }
      .hiw_card:nth-child(1)::after, .hiw_card:nth-child(3)::after {
        right: -10%;
        transform: rotate(90deg);
        top: 150px;
      }
      .hiw_card:nth-child(2)::after {
        right: -10%;
        top: 150px;
        transform: rotate(90deg);
      }
      .hiw_card:nth-child(3)::after {
        right: -10%;
      }
      .hiw_card:nth-child(4)::after {
        left: auto;
        transform: rotate(90deg);
        top: 150px;
        right: -10%;
      }
}
@media screen and ( max-width:880px){
    .hero_wrap {
        max-height: 600px;}
        .ownr_bio {
            flex-wrap: nowrap;
            flex-direction: column;
        }
        .ownr_bio, .ob_img {
           
            width: 100%;
        }
        .ob_img img {
            width: 100%;
            object-fit: cover;
            max-height: 350px;
            max-width: inherit;
            object-position: top;
        }
        .ownr_bio .ob_info {
            max-width: inherit;
            padding: 25px 25px 0 25px;
        }
}
@media screen and ( max-width:768px){
    .service_tabs.nav-tabs .nav-link{font-size: 1rem;}
    .service_tabs.nav-tabs .nav-link::after {
        width: 50px;
        height: 50px;
        top: 5%;
        left: calc(50% - 25px);
        transform: translateX(0px);
      }
      .service_tabs.nav-tabs .nav-link {
        margin: 16px;
        padding: 5rem 1.5rem 1.5rem;
      }
    .hero_text h2 {
        font-size: 2rem;
        margin-bottom: 10px !important;
    }
    .hero_wrap {
        max-height: 800px;
        flex-direction: column;
        flex-wrap: inherit;
    }
    .hero_text {
        width: 100%;
        margin: 0 auto;
    }
    .hero_bg {
        background: linear-gradient(180deg,rgba(252,253,254,1) 0,rgba(252,253,254,1) 75%,rgba(11,31,101,1) 75%,rgba(11,31,101,1) 100%);
    }
    .hero_bg::after {
        background-size: 50%;
    }
    .hero_img {
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
    }
    .hero_text .mt-4{ margin-top: 0px !important;}
    .hero_bg::before{ display: none;}
    .hero_text {
        height: initial;
        margin-top: 120px !important;
    }
}
@media screen and (max-width: 680px){
    .lps_features_list > li {
        width: 100%;
      }
      .service_tabs.nav-tabs {
        flex-wrap: wrap;
        justify-content: center;
      }
      .hiw_card::after {
        width: 80px;
        height: 18px;}
        .hiw_card:nth-child(1)::after, .hiw_card:nth-child(2)::after,.hiw_card:nth-child(3)::after, .hiw_card:nth-child(4)::after{
top: 100%; right: 0;
        }
    .lp_footer .row div.col-md-4:first-child{display: flex; margin-bottom: 2rem;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        flex-wrap: nowrap;}
        .foot_logo {
            max-width: none;
            margin-left: 0;
        }
        .foot_logo img{
            max-width: 200px; margin-bottom: 0.5rem;
        }
        .social_links{margin-top: 0;}
        .foot_inner_wrap {
            max-width: initial;
        }
}
@media screen and (max-width: 580px){
    .service_tabs.nav-tabs .nav-link{max-width: 180px; padding: 5rem .5rem .5rem; margin: 8px;}
    .service_tabs.nav-tabs .nav-link.active {
        box-shadow: 0 0 0 10px #EFF3FF;
      }
      .service_tabs.nav-tabs .nav-link.active::before {
        width: calc(100% + 20px);
        left: -10px;}
        .hiw_card .hiw_img{width: 80px;}
        .hiw_card .hiw_img img{max-width: 100%;}
        .hiw_card div:last-child{flex: 3;}
}


@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:wght@400;600;700&display=swap);
:root{
    --primary-blue:#0B1F65;
    --primary-light:rgba(11, 31, 101,0.15);
    --primary-gold:rgb(226, 179, 39);
    --primary-dark:#1d1d1d;
    --text_light:#000; /*6A7784*/
	--text-gray:rgba(0,0,0,.55);
    --transition: all ease 0.3s;
}
body{font-family:Montserrat,sans-serif;}
h1,h2,h3,h4,h5,h6{font-family:Montserrat,sans-serif; font-weight:600;}
.te_logo{display:flex;align-items:center;gap:15px}
.te_logo img{ max-width:20%;}
.te_logo span{font-weight:700; color: var(--primary-blue);}
.lp_menu.navbar-expand .navbar-nav .nav-link{padding-left: 1.25em;padding-right: 1.25em;font-weight: 600;text-transform: uppercase;font-size: 0.85rem; color: var(--text-gray);}
.hero_bg{background:#fcfdfe;background:linear-gradient(90deg,rgba(252,253,254,1) 0,rgba(252,253,254,1) 75%,rgba(11,31,101,1) 75%,rgba(11,31,101,1) 100%); position: relative; z-index: 0;}
.hero_bg::after {
    background-image: url('../images/hero_bg_symbol.svg');
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    transform: translate(-2%,8%);
    background-size: contain;
    max-width: 800px;
    max-height: 800px;
    bottom: 0;
}
.hero_bg::before{ content: ''; position: absolute; background-image: url('../images/Talk_Electrical_word.svg'); background-repeat: no-repeat; width: 60px; height: 100%;right: 2%; top:0;z-index: -1; background-size: contain; background-position:center;}
.hero_wrap{height:100vh;max-height:900px;display:flex;align-items:flex-end;flex-wrap:wrap;justify-content:space-between; z-index: 9; position: relative;}
.hero_wrap::before{ content: ''; position: absolute; background-image: url('../images/blue_dot_ptrn.svg'); background-repeat: no-repeat; width: 130px; height: 130px;left: -5%; top: 65%;z-index: -1;}

.te_main_lpage{ background-color: white;}
.te_main_lpage .container{max-width:1440px}
.te_main_lpage p{ color: var(--text_light); line-height:30px;}
.hero_img{max-width:650px}
.hero_text{max-width:650px;display:flex;align-items:flex-start;flex-direction:column;justify-content:center;height:100%}
.sub_head{font-family:Montserrat,sans-serif;font-weight:700;text-transform:uppercase;color:var(--primary-gold)}
.hero_text h2{font-size:4rem;color:var(--primary-blue)}
.hero_text p{color:var(--text_light);font-size:1.25rem}
.gold_btn{ background-color: var(--primary-gold) !important; color: white !important; font-weight: 600 !important; padding: 0.60em 1em;box-shadow: 1px 0px 4px rgba(226, 179, 39,.25), 0 7px 18px rgba(226, 179, 39,.15) ;}
.gold_btn:hover{background-color: var(--primary-dark) !important;box-shadow: 1px 0px 4px rgba(0,2,4,.055), 0 7px 18px rgba(1,1,1,.05);}

.page_title{ text-align: center; padding-top: 70px; margin-top: 30px; background-image: url('../images/te_title_icon.svg');background-repeat: no-repeat; background-position: center top;}
.page_title span{ font-size: 1rem; text-transform: uppercase; color: var(--primary-gold); display: block; margin-bottom: 10px;font-family:Montserrat,sans-serif;font-weight:600;}
.page_title h2{ font-size: 2.25rem; color: var(--primary-blue); display: block; margin-bottom: 10px;}
.page_title p{color:var(--text_light); max-width: 700px; margin: 15px auto;}
.lpservice_wrap{ position: relative;}
.lpservice_wrap::after{content: ''; position: absolute; background-image: url('../images/blue_dot_ptrn.svg'); background-repeat: no-repeat; width: 130px; height: 130px;right: -20px;   bottom: -50px;z-index: 0;}
.service_tabs.nav-tabs{ border-bottom: 0px; margin-bottom: 0;}
.service_tabs.nav-tabs{flex-wrap: nowrap;}
.service_tabs.nav-tabs .nav-link{ width: 100%; max-width: 300px; margin: 20px; border-radius: 10px; text-align: center; font-size: 1.25rem;  color:var(--primary-blue); background-color: white; padding: 10rem 2rem 2rem 2rem;box-shadow: 0px 0px 2px 0px #a7c4d2; border:none; position: relative;}
.service_tabs.nav-tabs .nav-link::after {
    width: 100px;
    height: 100px;
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: rgba(226, 179, 39,0.2);;
    top: 10%;
    left: 50%;
    transform: translateX(-50px);
}
.service_tabs.nav-tabs .nav-link.active{ box-shadow: 0 0 0 20px #EFF3FF; position: relative;}
.service_tabs.nav-tabs .nav-link.active::before {
    content: '';
    position: absolute;
    bottom: -20px;
    width: calc(100% + 40px);
    height: 20px;
    left: -20px;
    background-color: #EFF3FF;
}
.lp_services{ padding: 2em; background-color: #EFF3FF; border-radius: 0 0 10px 10px; position: relative; z-index: 5;}
.icon_gold { color: var(--primary-gold);  }
.lps_features_list li{ display: flex; gap: 15px;}
.fea_desc h5{color: var(--primary-blue); }
.lps_features_list {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.lps_features_list >li {
    width: 50%; margin-bottom: 15px;
}
.lps_intro h4{color: var(--primary-blue); font-size: 1.75rem; margin-bottom: 0.75em;}
.lps_intro p{ color: var(--text_light); font-size: 1.1rem; line-height: 2rem;}
.lps_features p,.hiw_card p{ color: var(--text_light);}
.hiw_card {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-evenly; margin: 15px;
}
.hiw_card:nth-child(odd){background-color: rgba(241, 244, 248,0.5);}
.hiw_card:nth-child(even){background-color: rgba(241, 244, 248,0.3);}
.hiw_card::after{content: ''; position: absolute; z-index: 4;  background-repeat: no-repeat; width: 130px; height: 31px; top: 100px; background-size: cover;}
.hiw_card:nth-child(1)::after,.hiw_card:nth-child(3)::after{ right: -30%; background-image: url('../images/hiw_arw.svg');}
.hiw_card:nth-child(2)::after {
    right: -30%;background-image: url('../images/hiw_arw.svg');
    transform: scaleY(-1);
    top: 150px;
}
.hiw_card:nth-child(4)::after {
    background-image: url('../images/hiw_arw.svg');
    left: 10%;
    transform: scaleY(1) rotate(130deg);
    bottom: -10%;
    top: auto;
}
.hiw_card h4{color: var(--primary-blue); font-size: 1.5rem; margin: 0.75em auto;}
.hiw_list{ display: flex; flex-wrap: wrap; justify-content: center;}
.hiw_list .hiw_card:nth-child(5) {
    flex-direction: row;
    max-width: 800px;
    min-height: 200px;
    text-align: left;
    justify-content: space-around;
    align-items: center;
}
.hiw_list .hiw_card:nth-child(5) div:nth-child(2){ max-width: 450px;}
.ready_sec h2{ font-size: 3rem; color: white;}
.ready_sec p{ color: #fff;}
.ownr_bio {
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    flex-wrap: wrap;align-items: center;
}
.ob_img img{ max-height: 700px; object-fit: cover; max-width: 500px;}
.ownr_bio .ob_info {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
}
.ob_info h2,.ob_info strong{ color: var(--primary-blue); margin-bottom: 15px; font-weight: 700;}
.ob_info p{ color: var(--text_light);}
.certi_list{ margin: 30px auto; padding: 0;}
.certi_list li{ width: 120px; display: inline-block; position: relative; text-align: center;}
.certi_list li::before{ content: ''; background-color: var(--primary-gold); width: 30px; height: 2px; position: absolute; left: 50%; transform: translateX(-50%); top: 0;}
.certi_list li span{ display: block; }
.certi_list li span.state_name{ font-size: 1.25rem;color: var(--text_light); margin: 10px auto;}
.certi_list li span.certi_num{ font-size: 1rem;color: var(--primary-gold);}
.mso_wrap{position: relative; min-height: 700px;}
.mso_wrap::before{ content: ''; width: 70%; height: 100%; top: 0; left: 0; bottom: 0; right: auto; border-radius:20px ; position: absolute; background-color: rgba(226, 179, 39,0.2); z-index: 0;}
.mso_wrap::after{ content: ''; width: 236px; height: 267px; top: 0; right: 0; position: absolute; background-image: url('../images/ylw_dot_ptrn.svg'); background-repeat: no-repeat;  z-index: 1 ;}

.mso_wrap *{ z-index: 2;}
.mso_wrap h4,.mso_wrap h5{ color: var(--primary-blue);}
.mso_info{ max-width: 500px; margin: 30px auto;}
.mso_list{ margin-top: 35px;}
.mso_list li {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.mso_list li .mso_icon{ width: 60px; margin-right: 25px;}
.mso_li_info{ width: calc(100% - 90px);}
.mso_wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.bs_box { max-width: 450px; width: 100%; margin: 10px auto; background-color: white; border-radius:5px;}
.bs_box img{ width: 100%; max-height: 250px; width: 100%; object-fit: cover; }
.bs_box h4{ font-size: 1.25rem; color: var(--primary-blue);}
.blog_details{ position: relative;}
.blog_date {
    position: absolute; font-size: 1.25rem;
    right: 30px;
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--text_light);
    color: white;
    border-radius: 10px;
    top: -40px;
    box-shadow: 0 7px 30px rgba(0,0,0,0.2); transition: var(--transition);
}
.blog_date span{ font-size: 1rem;}
.bs_box:hover .blog_date {
    background-color: var(--primary-blue);
    box-shadow: 0 7px 30px rgba(0,0,0,0.2);
}
.link_btn{ color: var(--primary-gold); margin-top: 15px;}
.link_btn:hover{color: var(--primary-blue);}
.home_blog_list{ position: relative;}
.home_blog_list::before{content: ''; position: absolute; background-image: url('../images/blue_dot_ptrn.svg'); background-repeat: no-repeat; width: 130px; height: 130px; left:-20px; top: 150px; z-index: 0;}
.rtgstrd{background-image: url('../images/foot_bg.png');background-position: top;background-size: cover;background-repeat: no-repeat;}
.lp_footer{ background-image: url('../images/foot_bg.png'); background-position: bottom; background-size: cover; background-repeat: no-repeat; /*min-height: 400px;*/ }
.foot_logo {
    max-width: 250px; margin: 0 auto;
}
.social_links{ margin-top: 50px; text-align: center;}
.social_links li { display: inline-block; margin: 5px;}
.social_links li a{ border: 1px solid white; width: 40px; height: 40px; display: flex; align-items: center; border-radius: 5px; justify-content: center; color: white; transition: var(--transition);}
.social_links li a:hover{ background-color: var(--primary-gold); border-color:  var(--primary-gold);}
.foot_inner_wrap {
    max-width: max-content;
    margin: 0 auto;
}
.foot_links li a,.foot_copy{ color: #CCD2D8; }
.foot_copy{font-weight:600;}
.foot_inner_wrap .foot_info li{ width: 100%; display: block;}
.foot_links li{ margin-bottom: 15px; width: 48%; display: inline-block;}
.foot_inner_wrap strong{color: #CCD2D8; font-size: 1.25rem; margin-bottom: 15px; display: block;}
.foot_links li a:hover{color:  var(--primary-gold);}

.comPageCon{ line-height:25px;}
.comPageCon .page_title h2{ text-transform:uppercase;}
.comPageCon .comPageInfo{ margin: 20px 0}
.comPageCon p{ margin-bottom:10px;}
.comPageCon ol, .comPageCon ul{ margin-bottom:10px;}

.hd_btn_grp{display: flex; gap: 0.5rem;}

@media screen and (max-width:680px) {
    .lp_footer{background-color: red;}
    .lp_footer .row div.col-md-4:first-child{display: flex;}
}

@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:wght@400;600;700&display=swap);
:root{
    --primary-blue:#0B1F65;
    --primary-light:rgba(11, 31, 101,0.15);
    --primary-gold:rgb(226, 179, 39);
    --primary-dark:#1d1d1d;
    --text_light:#000; /*6A7784*/
	--text-gray:rgba(0,0,0,.55);
    --transition: all ease 0.3s;
}

ul{ margin: 0; padding: 0; list-style: none;}
body{font-family:Montserrat,sans-serif;font-size:15px;/*font-family: 'Inter', sans-serif;*/}
a,a:hover{ text-decoration: none;}

/* sucit css start */
.invalid-feedback{
    display: inline-block !important;
    font-size: 14px !important;
}
.Err-feedback span{ color: rgb(191, 31, 2);margin-top: 3px;letter-spacing: 0;font-size: 13px;font-weight: 600;}
.reg_wrap {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 900px;
    min-height: 568px;
    background-image: url('../../landingPage/images/hero_bg_symbol.svg');
    background-repeat: no-repeat; background-size: contain;
    background-size: 30%;
    background-position: bottom left;
}
.reg_form{ padding: 15px;width: 100%;margin: 0px auto 0 auto;max-width: 450px; font-size:15px; color:#000}
.reg_form a, .reg_form a:hover{ text-decoration: underline; font-weight:600; color:rgb(0, 63, 196);}
.reg_form h2{ margin-bottom: 15px; color: #222; font-size: 1.6rem;}
.reg_form span{ margin-bottom: 15px; display: block;}
.alert{ padding:0.5rem 1rem; font-weight:600; font-size:0.8rem;}
.reg_form .ctrl-lbl{ font-weight:600;font-size: 0.85rem;}
.reg_form .acc_mode_change { font-size: 0.84rem; }

.form_control label{ font-size: 15px; font-weight: 600; margin-bottom: 5px;display: block;}
.form_control input{ width: 100%; margin-bottom: 5px; border: 1px solid #dedede; height: 50px; padding: 0px 15px; border-radius: 3px;}

.error_msg{ margin: 5px 0; color: crimson; display: block; font-size: 12px;}

.reg_form button{ letter-spacing:0.1px; width: 100%; font-size:13px; height:40px;}
.acc_mode_change{ margin-top: 15px;}

.form_control.invalid input{ border-color: crimson; background-color: #ffe6e6;}  
.invalid{ border-color: crimson !important; box-shadow: none !important;}
.form_label {font-weight: 500;}
.forms_input{height: 40px !important; font-size: 0.9rem;}
.forms_input:focus{border: 1px solid #000 !important; box-shadow: none !important;}/*0000ffa1*/
.forms_input_border:focus{border: 1px solid #000 !important; box-shadow: none !important;}
/* sucit css end */


.bgDark{ background-color: #2A3B4B;}
.bgLight{ background-color: #F1F4F8;}
.dark_light_gradient{
    background: rgb(241,244,248);
    background: linear-gradient(0deg, rgba(241,244,248,1) 0%, rgba(241,244,248,1) 50%, rgba(42,59,75,1) 50%, rgba(42,59,75,1) 100%); 
}
.white_light_gradient{
    background: rgb(241,244,248);
    background: linear-gradient(0deg, rgba(241,244,248,1) 0%, rgba(241,244,248,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); 
}
.navbar-nav .nav-link{ font-weight:600;}
.mainNav.navbar{ border-bottom: 1px solid rgba(11, 31, 101, 0.08);box-shadow: rgba(11, 31, 101, 0.05) 0px 3px 5px;}
.mainNav .nav-link.active,.mainNav .nav-link:hover{ background-color: white; color: var(--primary-blue) !important;}
.mainNav .nav-link{ position: relative; padding: 0.5rem 1.5rem !important; font-weight: 500;}

.mainNav .nav-link.active::before,.mainNav .nav-link:hover::before{ width: 100%; }

.mainNav .nav-link::before,.mainNav .nav-link::before {
    transition: all ease-in-out 0.3s;
    width: 0px;
    height: 2px;
    border-radius: 2px;
    content: '';
    left: 50%;
    transform: translateX( -50%);
    transition: all ease 0.3s;
    background-color: var(--primary-gold); top: calc(-.5rem - 5px);
    z-index: 2;
    position: absolute;
}

/*----questions------------*/
.inner_wrapper {
    margin-top: 75px;
    min-height: calc(100vh - 20px); background-color: #ffff;
    background-image: url('../../landingPage/images/hero_bg_symbol.svg');
    background-repeat: no-repeat; background-size: contain;
    background-size: 30%;
    background-position: bottom left;
    background-attachment: fixed; border-top: 1px solid #eee;
}
.ques_wrap {
    max-width: 700px;
    margin: 25px auto;
}
.ques_list{ margin-bottom:1.5em ;}
.ques_item:hover,.my_ques:hover{ box-shadow: rgba(11, 31, 101, 0.1) 0px 3px 5px;}
.ques_item strong{ font-size: 1rem;transition: var(--transition);}
.ques_item:hover strong{ color: var(--primary-blue); text-indent: 5px;}
.ques_item svg{ transition: var(--transition);stroke:var(--primary-light);}
.ques_item svg circle{fill:var(--primary-light)}
.ques_item:hover svg { stroke:var(--primary-gold);}
.ques_item:hover svg circle{fill:var(--primary-gold)}
.ques_item {
    border: 1px solid rgba(11, 31, 101, 0.08); cursor: pointer;
    padding: 1em;
    margin-bottom: 0.5em;
    border-radius: 10px;
    transition: var(--transition);
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between; position: relative;
}
.btns_grp{ display: flex; justify-content: space-between;}
.btns_grp button{ margin-top: 0; width: auto; border: none;}
.ques_price{ background-color: var(--primary-blue); font-size: 1em;}
.ques_list .selected{box-shadow: 0px 0px 0px 2px var(--primary-blue); background-color: white;}
.ques_list .selected strong{color: var(--primary-blue);}
.ques_item textarea,.ques_item .form-group{ width: 100%;}
.my_ques {
    border: 1px solid rgba(11, 31, 101, 0.08); flex-direction: column;
    
    margin-bottom: 2em;
    border-radius: 10px;
    transition: var(--transition);
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between; position: relative; background-color: white;
}
.my_ques::after {
    content: "Question";
    position: absolute;
    background-color: white;
    border: 1px solid #dedede;
    font-size: 11px;
    top: -13px;
    padding: 3px 14px;
    border-radius: 20px;
    left: 20px;
}
.chk_event::after{
content: "Event" !important;
}
.mq_head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start; padding: 25px 15px 5px 15px;
}
.mq_head h5 {
    display: inline-block;
    max-width: 500px;
}
.mq_body {
    width: 100%;
    margin-top: 0px;padding: 0px 15px;
}
.mq_status li span{ display: block; font-size: 1.10rem; margin-top: 8px;}
.mq_status li span.alert {
    font-size: 0.75rem;
    padding: 0.5em 0.75em;
    width: max-content; margin-bottom: 0;
}
.mq_status {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.mq_status li{ flex-basis: 33%; margin: 0.75em 0;}
.mq_status li:nth-child(4){flex-basis: 100%;}

.my_ques .btns_grp {
    border-top: 1px solid rgba(11, 31, 101, 0.08);
    width: 100%;
    padding: 10px 15px;
    margin-top: 10px;
    justify-content: flex-end;
}
.btns_grp .gold_btn{ padding:0.5em 1em !important; font-size: 0.85rem; }

.user_head {
    display: flex;
    align-items: center;
}
.user_head button{
     font-weight:600;
	 color: var(--text-gray);	 
}
.user_head.home-page button{
 color:#fff;
}
.bg-white .user_head.home-page button{
 color: var(--text-gray);	 
}
.user_head .uh_img{ width: 30px; height: 30px; border-radius: 50%; overflow: hidden; border: 2px solid var(--text_light);}
.user_head .uh_img img{ width: 30px; height: 30px; border-radius: 50%; margin-right: 0px; object-fit: cover;}
.navbar-expand .navbar-nav .user_head .dropdown-menu{right: 0;}
.user_head .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: none;
}
.dropdown-menu {    font-size: 0.85rem;}
.inner_header{ background-color: whitesmoke;}
.te_card {
    border: 1px solid rgba(11, 31, 101, 0.08);
    margin-bottom: 1.5em;
    border-radius: 10px;
    transition: var(--transition);
    min-height: 70px;
    position: relative;
    background-color: white;
}
.feedback_box .mq_head{border-bottom: 1px solid rgba(11, 31, 101, 0.08);padding: 25px 15px 15px 15px;}
.feedback_box .mq_body{ padding: 0px;}
.feedback_box .btns_grp {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid rgba(11, 31, 101, 0.08);
}
.feedback_box .form-group{background-color: #f5f5f5;}
.feedback_box textarea.form-control{ background-color: #f5f5f5; border: none;}
.feedback_box .form_label {
    margin: 10px 15px 5px 15px;
    color: var(--primary-gold);
}
.feedback_box .forms_input_border:focus{ border: none !important;}
.dash_card {
    display: flex;
    min-height: 130px;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
}
.dc_icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px; border-radius: 50%; margin-right: 30px; 
}
.dash_card:hover{ cursor: pointer; }
.shadow-sm:hover{box-shadow: 0 1rem 3rem rgba(0,0,0,.175) !important;}
.dci_1 .dc_icon,.dci_3 .dc_icon{ background-color: rgba(226, 179, 39,0.1);color: var(--primary-gold); transition: var(--transition);}
.dci_2 .dc_icon,.dci_4 .dc_icon{ background-color:rgba(11, 31, 101,0.1);color: var(--primary-blue); transition: var(--transition); }
.dc_info h2{ font-size: 2rem; margin-bottom: 0px; color:#212529;}

.dci_1:hover .dc_icon,.dci_3:hover .dc_icon{ background-color: rgb(226, 179, 39); color: white;}
.dci_2:hover .dc_icon,.dci_4:hover .dc_icon{ background-color:rgba(11, 31, 101,1);color: white }
 
.dci_1 small,.dci_3 small{ color: var(--primary-gold); font-weight:600;}
.dci_2 small,.dci_4 small{ color: var(--primary-blue); font-weight:600;}
.dc_info h2 span{ font-size: 13px; margin-right: 5px;}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: transparent;
    isolation: isolate; 
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 3px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1rem 1.75rem;color: var(--text_light);
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--primary-dark);
    background-color: #fff;
    border-color: #fff #fff var(--primary-gold);
}
.te_main_lpage p.foot_p{color: white; margin: 0.5rem 0;}
.reviewBox {
    width: 100%;
    max-width: 470px;
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    border-bottom: 1px solid rgba(11, 31, 101, 0.08);
    box-shadow: rgba(11, 31, 101, 0.05) 0px 3px 5px;
}
.rev_img {
    width: 50px;
    overflow: hidden;
    border-radius: 50%; box-shadow: rgba(11, 31, 101, 0.05) 0px 3px 5px; margin-right: 15px;
}
.rev_img img{
    width: 50px; height: 50%; object-fit: cover;
    border-radius: 50%;
}
.quote_icon {
    margin-bottom: 10px;
}
.te_main_lpage .reviewBox p{ font-style: italic; font-size: 14px; line-height: 24px;}
.rev_by {
    margin-top: 15px;
    display: flex;
    align-items: center;
}
.rev_by h5{color: var(--primary-blue); font-size: 16px; margin-bottom: 0;}
.rev_by h5 span{color: var(--text_light); display: block; font-size: 13px;}

.reviewCaro .owl-stage-outer{ padding: 30px 0 20px 0;}
.reviewCaro .owl-stage-outer .owl-item.active{opacity: 1;}
.reviewCaro .owl-stage-outer .owl-item{opacity: 0.5;}
.owl-carousel .owl-nav{ margin-top: 0;}
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{width: 40px; height: 40px; border-radius: 20px; background-color: white;border: 1px solid rgba(11, 31, 101, 0.08);}

.ref-pgrm-content{ margin-top:15px;} 
.ref-pgrm-content .int-lbl{ font-weight:600; font-size:15px;}
.ref-pgrm-content .form-control { font-size:0.9rem}
.ref-pgrm-content button{ padding:0 60px; height:40px; letter-spacing:0; margin-top:15px;}
.ref-pgrm-content .inst{ background-color:#fff; border:1px solid #ddd; padding:20px;}
.ref-pgrm-content .inst h6{ font-size:0.98rem}
.ref-pgrm-content .inst p{ font-size:0.98rem; margin-bottom:0; line-height:30px; margin-left:10px;}

.dash_card.dash_ask_qus {
    background-color: var(--primary-gold);
    border-radius: 10px; transition: var(--transition);
}
.dash_card.dash_ask_qus a{
    display: flex;
    justify-content: space-evenly;
    color: white;
    align-items: center;width: 100%;
    font-size: 20px;
    font-weight: 600;
}
.dash_card.dash_ask_qus:hover{ background-color: var(--primary-blue);}
.dash_card.dash_ask_qus a span{ width: 40px; height: 40px; background-color: rgba(255,255,255,0.2); display: flex; justify-content: center; align-items: center; border-radius: 50%; visibility: hidden; transition: var(--transition); transform: translate(100px,0);}
.dash_card.dash_ask_qus:hover a span{ transform: translate(0,0); visibility: visible;}
.my_wallet {
    padding: 5px 15px;
    background-color: var(--primary-gold);
    border-radius: 20px;
    font-size: 13px;
    font-weight: 600;
    color: white;
    margin-right: 15px;
}
.my_wallet svg{
    margin-right: 5px;
}
.ref-pgrm .container{ max-width: 700px; margin: 25px auto;}
.ref_img{ max-width: 500px; margin: 10px auto;}
.reff_inst{ margin-top: 25px;}
.reff_inst h6{ font-size: 15px; line-height: 26px;}
.reff_inst ul{ padding-left: 15px; margin-top: 15px;}
.reff_inst ul li{ list-style-type: square; margin-bottom: 10px;}

.dropdown-item.active, .dropdown-item:active{background-color: var(--primary-blue) }

.braintree-show-methods [data-braintree-id='methods-label'], .braintree-show-methods [data-braintree-id='methods-edit'], .braintree-show-options [data-braintree-id='choose-a-way-to-pay']{ font-weight:600;}
.paymentPage .my_ques .btns_grp{justify-content: flex-start}
.paymentPage .btns_grp .gold_btn {
    padding: 0.5em 5em !important;
    font-size: 0.85rem;
}

.Toastify__toast{
	min-height: 55px;
	margin-bottom: 1rem;
}
.Toastify__toast--success{background:#568203;}

.psp .srtDesc{ text-align: center;line-height: 25px;font-size: 15px; padding-top:10px;}
/*.psp .forms_input{font-size:0.84rem}*/

.live_indi {
    width: 80px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid #c4c4c4;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 30px;
    margin-bottom: 10px;
}
.live_indi .spinner-grow {
    width: 1rem;
    height: 1rem;
}

.live_event_item .event_info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}
.live_event_item .event_date{ width: auto; padding: .5rem 1rem;}
.live_event_item .event_title{ height: auto;}
.live_event_item .event_info li{ width: auto; flex-basis: 150px;}

.walNotice{border: 1px dashed #555;    padding: 10px 30px;}

.pro_filter {
    max-width: 700px;
    margin: 10px auto;
}
.pro_filter .form-select {
    padding: 0.55em .75em !important;
}
.pro_item_box {
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    gap: 20px; background-color: white; margin-bottom: 10px; transition: all ease .3s; position: relative;
  }
.pro_item_box::before{ width: 5px; height: 30px; position: absolute; top: 15px; left: 0; content: ""; background-color: var(--primary-gold);}
.pro_item_box:hover::before{ background-color: var(--primary-blue);}
.pro_item_box:hover{ box-shadow: 0 7px 30px rgba(0,0,0,.11);}
.pib_img{ width: 40px; height: 40px; display: none; justify-content: center; align-items: center; background-color: var(--primary-gold); border-radius: 50%; color: white; }
.type_of{ font-size: 14px; font-weight: 600; background-color: #F1F4F8; padding: 5px 10px; border-radius: 10px; margin-bottom: 8px; display: inline-block;}
.pro_address{ margin-bottom: 0;}
.pro_address svg{ color: var(--primary-gold); margin-right: 5px;}
.pro_name{ color: var(--primary-blue);}

.become_pro{background-color:#f1f1f1;padding:15px;border-radius:10px;width:100%;max-width:700px;margin:20px auto;text-align:center;display:flex;align-items:center;gap:15px;justify-content:space-evenly;flex-wrap:wrap;min-height:150px}
.become_pro p{margin-bottom:0}
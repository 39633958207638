@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:wght@400;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Serif+Display&family=Montserrat:wght@400;600;700&display=swap);
:root{
    --primary-blue:#0B1F65;
    --primary-light:rgba(11, 31, 101,0.15);
    --primary-gold:rgb(226, 179, 39);
    --primary-dark:#1d1d1d;
    --text_light:#000; /*6A7784*/
	--text-gray:rgba(0,0,0,.55);
    --transition: all ease 0.3s;
}
body{font-family:Montserrat,sans-serif;}
h1,h2,h3,h4,h5,h6{font-family:Montserrat,sans-serif; font-weight:600;}
.te_logo{display:flex;align-items:center;grid-gap:15px;gap:15px}
.te_logo img{ max-width:20%;}
.te_logo span{font-weight:700; color: var(--primary-blue);}
.lp_menu.navbar-expand .navbar-nav .nav-link{padding-left: 1.25em;padding-right: 1.25em;font-weight: 600;text-transform: uppercase;font-size: 0.85rem; color: var(--text-gray);}
.hero_bg{background:#fcfdfe;background:linear-gradient(90deg,rgba(252,253,254,1) 0,rgba(252,253,254,1) 75%,rgba(11,31,101,1) 75%,rgba(11,31,101,1) 100%); position: relative; z-index: 0;}
.hero_bg::after {
    background-image: url(/static/media/hero_bg_symbol.1cc20ebd.svg);
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    transform: translate(-2%,8%);
    background-size: contain;
    max-width: 800px;
    max-height: 800px;
    bottom: 0;
}
.hero_bg::before{ content: ''; position: absolute; background-image: url(/static/media/Talk_Electrical_word.649df5ae.svg); background-repeat: no-repeat; width: 60px; height: 100%;right: 2%; top:0;z-index: -1; background-size: contain; background-position:center;}
.hero_wrap{height:100vh;max-height:900px;display:flex;align-items:flex-end;flex-wrap:wrap;justify-content:space-between; z-index: 9; position: relative;}
.hero_wrap::before{ content: ''; position: absolute; background-image: url(/static/media/blue_dot_ptrn.486527d4.svg); background-repeat: no-repeat; width: 130px; height: 130px;left: -5%; top: 65%;z-index: -1;}

.te_main_lpage{ background-color: white;}
.te_main_lpage .container{max-width:1440px}
.te_main_lpage p{ color: var(--text_light); line-height:30px;}
.hero_img{max-width:650px}
.hero_text{max-width:650px;display:flex;align-items:flex-start;flex-direction:column;justify-content:center;height:100%}
.sub_head{font-family:Montserrat,sans-serif;font-weight:700;text-transform:uppercase;color:var(--primary-gold)}
.hero_text h2{font-size:4rem;color:var(--primary-blue)}
.hero_text p{color:var(--text_light);font-size:1.25rem}
.gold_btn{ background-color: var(--primary-gold) !important; color: white !important; font-weight: 600 !important; padding: 0.60em 1em;box-shadow: 1px 0px 4px rgba(226, 179, 39,.25), 0 7px 18px rgba(226, 179, 39,.15) ;}
.gold_btn:hover{background-color: var(--primary-dark) !important;box-shadow: 1px 0px 4px rgba(0,2,4,.055), 0 7px 18px rgba(1,1,1,.05);}

.page_title{ text-align: center; padding-top: 70px; margin-top: 30px; background-image: url(/static/media/te_title_icon.be94c652.svg);background-repeat: no-repeat; background-position: center top;}
.page_title span{ font-size: 1rem; text-transform: uppercase; color: var(--primary-gold); display: block; margin-bottom: 10px;font-family:Montserrat,sans-serif;font-weight:600;}
.page_title h2{ font-size: 2.25rem; color: var(--primary-blue); display: block; margin-bottom: 10px;}
.page_title p{color:var(--text_light); max-width: 700px; margin: 15px auto;}
.lpservice_wrap{ position: relative;}
.lpservice_wrap::after{content: ''; position: absolute; background-image: url(/static/media/blue_dot_ptrn.486527d4.svg); background-repeat: no-repeat; width: 130px; height: 130px;right: -20px;   bottom: -50px;z-index: 0;}
.service_tabs.nav-tabs{ border-bottom: 0px; margin-bottom: 0;}
.service_tabs.nav-tabs{flex-wrap: nowrap;}
.service_tabs.nav-tabs .nav-link{ width: 100%; max-width: 300px; margin: 20px; border-radius: 10px; text-align: center; font-size: 1.25rem;  color:var(--primary-blue); background-color: white; padding: 10rem 2rem 2rem 2rem;box-shadow: 0px 0px 2px 0px #a7c4d2; border:none; position: relative;}
.service_tabs.nav-tabs .nav-link::after {
    width: 100px;
    height: 100px;
    content: '';
    position: absolute;
    border-radius: 50%;
    background-color: rgba(226, 179, 39,0.2);;
    top: 10%;
    left: 50%;
    transform: translateX(-50px);
}
.service_tabs.nav-tabs .nav-link.active{ box-shadow: 0 0 0 20px #EFF3FF; position: relative;}
.service_tabs.nav-tabs .nav-link.active::before {
    content: '';
    position: absolute;
    bottom: -20px;
    width: calc(100% + 40px);
    height: 20px;
    left: -20px;
    background-color: #EFF3FF;
}
.lp_services{ padding: 2em; background-color: #EFF3FF; border-radius: 0 0 10px 10px; position: relative; z-index: 5;}
.icon_gold { color: var(--primary-gold);  }
.lps_features_list li{ display: flex; grid-gap: 15px; gap: 15px;}
.fea_desc h5{color: var(--primary-blue); }
.lps_features_list {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}
.lps_features_list >li {
    width: 50%; margin-bottom: 15px;
}
.lps_intro h4{color: var(--primary-blue); font-size: 1.75rem; margin-bottom: 0.75em;}
.lps_intro p{ color: var(--text_light); font-size: 1.1rem; line-height: 2rem;}
.lps_features p,.hiw_card p{ color: var(--text_light);}
.hiw_card {
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    padding: 15px;
    text-align: center;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-evenly; margin: 15px;
}
.hiw_card:nth-child(odd){background-color: rgba(241, 244, 248,0.5);}
.hiw_card:nth-child(even){background-color: rgba(241, 244, 248,0.3);}
.hiw_card::after{content: ''; position: absolute; z-index: 4;  background-repeat: no-repeat; width: 130px; height: 31px; top: 100px; background-size: cover;}
.hiw_card:nth-child(1)::after,.hiw_card:nth-child(3)::after{ right: -30%; background-image: url(/static/media/hiw_arw.438cf76e.svg);}
.hiw_card:nth-child(2)::after {
    right: -30%;background-image: url(/static/media/hiw_arw.438cf76e.svg);
    transform: scaleY(-1);
    top: 150px;
}
.hiw_card:nth-child(4)::after {
    background-image: url(/static/media/hiw_arw.438cf76e.svg);
    left: 10%;
    transform: scaleY(1) rotate(130deg);
    bottom: -10%;
    top: auto;
}
.hiw_card h4{color: var(--primary-blue); font-size: 1.5rem; margin: 0.75em auto;}
.hiw_list{ display: flex; flex-wrap: wrap; justify-content: center;}
.hiw_list .hiw_card:nth-child(5) {
    flex-direction: row;
    max-width: 800px;
    min-height: 200px;
    text-align: left;
    justify-content: space-around;
    align-items: center;
}
.hiw_list .hiw_card:nth-child(5) div:nth-child(2){ max-width: 450px;}
.ready_sec h2{ font-size: 3rem; color: white;}
.ready_sec p{ color: #fff;}
.ownr_bio {
    display: flex;
    border-radius: 20px;
    overflow: hidden;
    flex-wrap: wrap;align-items: center;
}
.ob_img img{ max-height: 700px; object-fit: cover; max-width: 500px;}
.ownr_bio .ob_info {
    width: 100%;
    max-width: 520px;
    margin: 0 auto;
}
.ob_info h2,.ob_info strong{ color: var(--primary-blue); margin-bottom: 15px; font-weight: 700;}
.ob_info p{ color: var(--text_light);}
.certi_list{ margin: 30px auto; padding: 0;}
.certi_list li{ width: 120px; display: inline-block; position: relative; text-align: center;}
.certi_list li::before{ content: ''; background-color: var(--primary-gold); width: 30px; height: 2px; position: absolute; left: 50%; transform: translateX(-50%); top: 0;}
.certi_list li span{ display: block; }
.certi_list li span.state_name{ font-size: 1.25rem;color: var(--text_light); margin: 10px auto;}
.certi_list li span.certi_num{ font-size: 1rem;color: var(--primary-gold);}
.mso_wrap{position: relative; min-height: 700px;}
.mso_wrap::before{ content: ''; width: 70%; height: 100%; top: 0; left: 0; bottom: 0; right: auto; border-radius:20px ; position: absolute; background-color: rgba(226, 179, 39,0.2); z-index: 0;}
.mso_wrap::after{ content: ''; width: 236px; height: 267px; top: 0; right: 0; position: absolute; background-image: url(/static/media/ylw_dot_ptrn.0ab9b1ca.svg); background-repeat: no-repeat;  z-index: 1 ;}

.mso_wrap *{ z-index: 2;}
.mso_wrap h4,.mso_wrap h5{ color: var(--primary-blue);}
.mso_info{ max-width: 500px; margin: 30px auto;}
.mso_list{ margin-top: 35px;}
.mso_list li {
    display: flex;
    margin-bottom: 20px;
    flex-wrap: wrap;
}
.mso_list li .mso_icon{ width: 60px; margin-right: 25px;}
.mso_li_info{ width: calc(100% - 90px);}
.mso_wrap {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.bs_box { max-width: 450px; width: 100%; margin: 10px auto; background-color: white; border-radius:5px;}
.bs_box img{ width: 100%; max-height: 250px; width: 100%; object-fit: cover; }
.bs_box h4{ font-size: 1.25rem; color: var(--primary-blue);}
.blog_details{ position: relative;}
.blog_date {
    position: absolute; font-size: 1.25rem;
    right: 30px;
    width: 80px;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--text_light);
    color: white;
    border-radius: 10px;
    top: -40px;
    box-shadow: 0 7px 30px rgba(0,0,0,0.2); transition: var(--transition);
}
.blog_date span{ font-size: 1rem;}
.bs_box:hover .blog_date {
    background-color: var(--primary-blue);
    box-shadow: 0 7px 30px rgba(0,0,0,0.2);
}
.link_btn{ color: var(--primary-gold); margin-top: 15px;}
.link_btn:hover{color: var(--primary-blue);}
.home_blog_list{ position: relative;}
.home_blog_list::before{content: ''; position: absolute; background-image: url(/static/media/blue_dot_ptrn.486527d4.svg); background-repeat: no-repeat; width: 130px; height: 130px; left:-20px; top: 150px; z-index: 0;}
.rtgstrd{background-image: url(/static/media/foot_bg.a172a67f.png);background-position: top;background-size: cover;background-repeat: no-repeat;}
.lp_footer{ background-image: url(/static/media/foot_bg.a172a67f.png); background-position: bottom; background-size: cover; background-repeat: no-repeat; /*min-height: 400px;*/ }
.foot_logo {
    max-width: 250px; margin: 0 auto;
}
.social_links{ margin-top: 50px; text-align: center;}
.social_links li { display: inline-block; margin: 5px;}
.social_links li a{ border: 1px solid white; width: 40px; height: 40px; display: flex; align-items: center; border-radius: 5px; justify-content: center; color: white; transition: var(--transition);}
.social_links li a:hover{ background-color: var(--primary-gold); border-color:  var(--primary-gold);}
.foot_inner_wrap {
    max-width: -webkit-max-content;
    max-width: max-content;
    margin: 0 auto;
}
.foot_links li a,.foot_copy{ color: #CCD2D8; }
.foot_copy{font-weight:600;}
.foot_inner_wrap .foot_info li{ width: 100%; display: block;}
.foot_links li{ margin-bottom: 15px; width: 48%; display: inline-block;}
.foot_inner_wrap strong{color: #CCD2D8; font-size: 1.25rem; margin-bottom: 15px; display: block;}
.foot_links li a:hover{color:  var(--primary-gold);}

.comPageCon{ line-height:25px;}
.comPageCon .page_title h2{ text-transform:uppercase;}
.comPageCon .comPageInfo{ margin: 20px 0}
.comPageCon p{ margin-bottom:10px;}
.comPageCon ol, .comPageCon ul{ margin-bottom:10px;}

.hd_btn_grp{display: flex; grid-gap: 0.5rem; gap: 0.5rem;}

@media screen and (max-width:680px) {
    .lp_footer{background-color: red;}
    .lp_footer .row div.col-md-4:first-child{display: flex;}
}

@media screen and (max-width:1440px) {
	/*.lp_menu { font-size:15px;}*/
    .service_tabs.nav-tabs .nav-link {
        max-width: 250px;
        margin: 20px;
        padding: 10rem 1.5rem 1.5rem;
    }
    .hiw_card { max-width: 260px;}
    .mso_info {
        max-width: 500px;
        margin: auto;
        width: 50%;
    }
    .mso_img {
        width: 50%;
    }
    .mso_img img{
        max-width: 100%;
    }
    .hero_text{ width: 55%;}
    .hero_img {
        width: 45%;
    }
    .hero_text h2 { font-size: 3rem;}
    .hero_text p { font-size: 1.1rem;}
    .page_title h2 { font-size: 2rem;}
    .page_title span {font-size: 0.85rem;}
    .lps_intro h4 { font-size: 1.5rem;}
    .hiw_card h4 {font-size: 1.3rem; }
    .te_main_lpage p {line-height: 24px;}
    .ready_sec h2 {font-size: 2.5rem;}
    .foot_logo {
        max-width: 200px;
    }
    .btn{ font-size: 0.85rem;}
    .ownr_bio .ob_info {
        /*max-width: 500px;*/max-width: 100%; padding:30px;
    }
}
@media screen and ( max-width:1366px) and (min-device-aspect-ratio: 4/3){
	body{font-size:14px;}
	
    .te_main_lpage .container,.container{max-width: 1200px;}
    /*.reg_form{
		max-width:360px;
	}*/
	 .reg_form img{ max-width: 12%;}
	.te_logo img{ max-width: 40px;}
    .hero_text h2 {
        font-size: 2.5rem;
    }
    .sub_head{ font-size: 1.25rem;}
    .hero_text p {
        font-size: 1rem;
    }
  
    .page_title h2 {
        font-size: 1.5rem;
    }
    .page_title span {
        font-size: 0.75rem;
    }
    .service_tabs.nav-tabs .nav-link{ font-size: 1rem;}
    .lps_intro h4 {
        font-size: 1.15rem;
    }
    .te_main_lpage p {
        line-height: 24px;
        font-size: 0.9rem;
    }
    .hiw_card h4 {
        font-size: 1rem;
    }
    .ob_info h2{
        font-size: 1.5rem;
    }
    .ob_info strong {
        font-size: .75rem;
    }
    .certi_list li span.state_name {
        font-size: 1rem;
    }
    .bs_box h4 {
        font-size: 1rem;
    }
    .fea_desc h5 {
        font-size: 1rem;
    }
	.btns_grp .gold_btn {
		font-size: 13px;
	}
	.h5, h5{ font-size:1.10rem;}
	
}
@media screen and ( max-width:1200px){
    .hero_wrap {
        max-height: 700px;}
        .ownr_bio .ob_info {
            max-width: 450px;padding: 25px;
        }
        .certi_list li {
            margin-bottom: 25px;
        }  
        .ownr_bio,
        .ob_img {
            height: 100%;
        }
        .ob_img img {
            max-height: 900px;
            object-fit: cover;
            max-width: 400px;
            height: 100%;
        }
        .hero_wrap::before{top: auto; bottom: 5%;}
       
}
@media screen and (max-width:1183px){
    .hiw_card:nth-child(3)::after {
        right: -30%;
        bottom: 0;
        top: auto;
        transform: rotate(90deg);
      }
    .hiw_card:nth-child(4){order: 5;} 
    .hiw_card:nth-child(4)::after {
        left: -30%;
        transform: scaleY(1) rotate(180deg);
        bottom: 80%;
      }
      .hiw_list .hiw_card:nth-child(5) {
        flex-direction: column;
        max-width: 260px;
        min-height: 200px;
        text-align: center;
        justify-content: space-around;
        align-items: center;
      }
}
@media screen and ( max-width:992px){
.hero_wrap {max-height: 600px;}
.mso_wrap {flex-direction: column;}
.mso_wrap::after,.mso_wrap::before{ display: none;}
.mso_info{ padding: 25px; max-width: 800px;width: 100%;height: 100%;border-radius: 20px;background-color: rgba(226, 179, 39,0.2);}
.mso_img {width: 100%;max-width: 500px;margin: -50px auto;}
.lp_menu {background-color: white;}
    .navbar-nav{align-items: center;}
    .hd_btn_grp{margin: 1rem auto;}
    .hero_wrap::before{display: none;}
    .hero_text p { font-size: 0.875rem;}
}
@media screen and ( max-width:910px){
    .hiw_card {
        max-width: 550px;
        flex-direction: row;
        align-items: center;
        min-height: 150px;
      }
      .hiw_card .hiw_img {
        width: 150px; flex: 1 1;
      }
      .hiw_card div:last-child{flex: 2 1;}
      .hiw_list .hiw_card:nth-child(5) {
        flex-direction: row;
        max-width: 550px;
        min-height: 150px;
        text-align: center;
        justify-content: space-around;
        align-items: center;
      }
      .hiw_card:nth-child(4) {
        order: 4;
      }
      .hiw_card:nth-child(5) {
        order: 5;
      }
      .hiw_card:nth-child(1)::after, .hiw_card:nth-child(3)::after {
        right: -10%;
        transform: rotate(90deg);
        top: 150px;
      }
      .hiw_card:nth-child(2)::after {
        right: -10%;
        top: 150px;
        transform: rotate(90deg);
      }
      .hiw_card:nth-child(3)::after {
        right: -10%;
      }
      .hiw_card:nth-child(4)::after {
        left: auto;
        transform: rotate(90deg);
        top: 150px;
        right: -10%;
      }
}
@media screen and ( max-width:880px){
    .hero_wrap {
        max-height: 600px;}
        .ownr_bio {
            flex-wrap: nowrap;
            flex-direction: column;
        }
        .ownr_bio, .ob_img {
           
            width: 100%;
        }
        .ob_img img {
            width: 100%;
            object-fit: cover;
            max-height: 350px;
            max-width: inherit;
            object-position: top;
        }
        .ownr_bio .ob_info {
            max-width: inherit;
            padding: 25px 25px 0 25px;
        }
}
@media screen and ( max-width:768px){
    .service_tabs.nav-tabs .nav-link{font-size: 1rem;}
    .service_tabs.nav-tabs .nav-link::after {
        width: 50px;
        height: 50px;
        top: 5%;
        left: calc(50% - 25px);
        transform: translateX(0px);
      }
      .service_tabs.nav-tabs .nav-link {
        margin: 16px;
        padding: 5rem 1.5rem 1.5rem;
      }
    .hero_text h2 {
        font-size: 2rem;
        margin-bottom: 10px !important;
    }
    .hero_wrap {
        max-height: 800px;
        flex-direction: column;
        flex-wrap: inherit;
    }
    .hero_text {
        width: 100%;
        margin: 0 auto;
    }
    .hero_bg {
        background: linear-gradient(180deg,rgba(252,253,254,1) 0,rgba(252,253,254,1) 75%,rgba(11,31,101,1) 75%,rgba(11,31,101,1) 100%);
    }
    .hero_bg::after {
        background-size: 50%;
    }
    .hero_img {
        width: 100%;
        max-width: 350px;
        margin: 0 auto;
    }
    .hero_text .mt-4{ margin-top: 0px !important;}
    .hero_bg::before{ display: none;}
    .hero_text {
        height: initial;
        margin-top: 120px !important;
    }
}
@media screen and (max-width: 680px){
    .lps_features_list > li {
        width: 100%;
      }
      .service_tabs.nav-tabs {
        flex-wrap: wrap;
        justify-content: center;
      }
      .hiw_card::after {
        width: 80px;
        height: 18px;}
        .hiw_card:nth-child(1)::after, .hiw_card:nth-child(2)::after,.hiw_card:nth-child(3)::after, .hiw_card:nth-child(4)::after{
top: 100%; right: 0;
        }
    .lp_footer .row div.col-md-4:first-child{display: flex; margin-bottom: 2rem;
        justify-content: space-between;
        align-items: center;
        grid-gap: 1rem;
        gap: 1rem;
        flex-wrap: nowrap;}
        .foot_logo {
            max-width: none;
            margin-left: 0;
        }
        .foot_logo img{
            max-width: 200px; margin-bottom: 0.5rem;
        }
        .social_links{margin-top: 0;}
        .foot_inner_wrap {
            max-width: initial;
        }
}
@media screen and (max-width: 580px){
    .service_tabs.nav-tabs .nav-link{max-width: 180px; padding: 5rem .5rem .5rem; margin: 8px;}
    .service_tabs.nav-tabs .nav-link.active {
        box-shadow: 0 0 0 10px #EFF3FF;
      }
      .service_tabs.nav-tabs .nav-link.active::before {
        width: calc(100% + 20px);
        left: -10px;}
        .hiw_card .hiw_img{width: 80px;}
        .hiw_card .hiw_img img{max-width: 100%;}
        .hiw_card div:last-child{flex: 3 1;}
}


.Button_button__3Ja_F{background-color:#0b1f65;color:#fff;height:50px;border-radius:3px;display:flex;justify-content:center;align-items:center;padding:0 15px; border:none; font-weight: 600; letter-spacing: 1px;}
.Button_button__3Ja_F:hover{opacity:0.8;cursor: pointer;}


.event_date{background-color:var(--primary-blue);color:#fff;height:30px;font-size:13px;font-weight:700;border-radius:15px;display:flex;width:220px;justify-content:center;align-items:center;text-align:center; letter-spacing: 0.5px;}
.event_foot{display:flex;justify-content:space-between;align-items:center;margin-top:15px;border-top:1px solid var(--bs-gray-200);padding-top:10px}
.event_title{font-size:22px;margin:10px auto;height:65px;overflow:hidden}
.event_title a{color:var(--text_light)}
.event_title a:focus,.event_title a:hover{color:var(--primary-blue)}
.event_info{display:table;width:100%}
.event_info li{display:table-cell;width:25%}
.event_info li:first-child{width:50%}
.event_info li span{display:block;font-weight:600}
.event_fees{font-size:1.2rem;font-weight:600}
:root{
    --primary-blue:#0B1F65;
    --primary-light:rgba(11, 31, 101,0.15);
    --primary-gold:rgb(226, 179, 39);
    --primary-dark:#1d1d1d;
    --text_light:#000; /*6A7784*/
	--text-gray:rgba(0,0,0,.55);
    --transition: all ease 0.3s;
}

ul{ margin: 0; padding: 0; list-style: none;}
body{font-family:Montserrat,sans-serif;font-size:15px;/*font-family: 'Inter', sans-serif;*/}
a,a:hover{ text-decoration: none;}

/* sucit css start */
.invalid-feedback{
    display: inline-block !important;
    font-size: 14px !important;
}
.Err-feedback span{ color: rgb(191, 31, 2);margin-top: 3px;letter-spacing: 0;font-size: 13px;font-weight: 600;}
.reg_wrap {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-height: 900px;
    min-height: 568px;
    background-image: url(/static/media/hero_bg_symbol.1cc20ebd.svg);
    background-repeat: no-repeat; background-size: contain;
    background-size: 30%;
    background-position: bottom left;
}
.reg_form{ padding: 15px;width: 100%;margin: 0px auto 0 auto;max-width: 450px; font-size:15px; color:#000}
.reg_form a, .reg_form a:hover{ text-decoration: underline; font-weight:600; color:rgb(0, 63, 196);}
.reg_form h2{ margin-bottom: 15px; color: #222; font-size: 1.6rem;}
.reg_form span{ margin-bottom: 15px; display: block;}
.alert{ padding:0.5rem 1rem; font-weight:600; font-size:0.8rem;}
.reg_form .ctrl-lbl{ font-weight:600;font-size: 0.85rem;}
.reg_form .acc_mode_change { font-size: 0.84rem; }

.form_control label{ font-size: 15px; font-weight: 600; margin-bottom: 5px;display: block;}
.form_control input{ width: 100%; margin-bottom: 5px; border: 1px solid #dedede; height: 50px; padding: 0px 15px; border-radius: 3px;}

.error_msg{ margin: 5px 0; color: crimson; display: block; font-size: 12px;}

.reg_form button{ letter-spacing:0.1px; width: 100%; font-size:13px; height:40px;}
.acc_mode_change{ margin-top: 15px;}

.form_control.invalid input{ border-color: crimson; background-color: #ffe6e6;}  
.invalid{ border-color: crimson !important; box-shadow: none !important;}
.form_label {font-weight: 500;}
.forms_input{height: 40px !important; font-size: 0.9rem;}
.forms_input:focus{border: 1px solid #000 !important; box-shadow: none !important;}/*0000ffa1*/
.forms_input_border:focus{border: 1px solid #000 !important; box-shadow: none !important;}
/* sucit css end */


.bgDark{ background-color: #2A3B4B;}
.bgLight{ background-color: #F1F4F8;}
.dark_light_gradient{
    background: rgb(241,244,248);
    background: linear-gradient(0deg, rgba(241,244,248,1) 0%, rgba(241,244,248,1) 50%, rgba(42,59,75,1) 50%, rgba(42,59,75,1) 100%); 
}
.white_light_gradient{
    background: rgb(241,244,248);
    background: linear-gradient(0deg, rgba(241,244,248,1) 0%, rgba(241,244,248,1) 50%, rgba(255,255,255,1) 50%, rgba(255,255,255,1) 100%); 
}
.navbar-nav .nav-link{ font-weight:600;}
.mainNav.navbar{ border-bottom: 1px solid rgba(11, 31, 101, 0.08);box-shadow: rgba(11, 31, 101, 0.05) 0px 3px 5px;}
.mainNav .nav-link.active,.mainNav .nav-link:hover{ background-color: white; color: var(--primary-blue) !important;}
.mainNav .nav-link{ position: relative; padding: 0.5rem 1.5rem !important; font-weight: 500;}

.mainNav .nav-link.active::before,.mainNav .nav-link:hover::before{ width: 100%; }

.mainNav .nav-link::before,.mainNav .nav-link::before {
    transition: all ease-in-out 0.3s;
    width: 0px;
    height: 2px;
    border-radius: 2px;
    content: '';
    left: 50%;
    transform: translateX( -50%);
    transition: all ease 0.3s;
    background-color: var(--primary-gold); top: calc(-.5rem - 5px);
    z-index: 2;
    position: absolute;
}

/*----questions------------*/
.inner_wrapper {
    margin-top: 75px;
    min-height: calc(100vh - 20px); background-color: #ffff;
    background-image: url(/static/media/hero_bg_symbol.1cc20ebd.svg);
    background-repeat: no-repeat; background-size: contain;
    background-size: 30%;
    background-position: bottom left;
    background-attachment: fixed; border-top: 1px solid #eee;
}
.ques_wrap {
    max-width: 700px;
    margin: 25px auto;
}
.ques_list{ margin-bottom:1.5em ;}
.ques_item:hover,.my_ques:hover{ box-shadow: rgba(11, 31, 101, 0.1) 0px 3px 5px;}
.ques_item strong{ font-size: 1rem;transition: var(--transition);}
.ques_item:hover strong{ color: var(--primary-blue); text-indent: 5px;}
.ques_item svg{ transition: var(--transition);stroke:var(--primary-light);}
.ques_item svg circle{fill:var(--primary-light)}
.ques_item:hover svg { stroke:var(--primary-gold);}
.ques_item:hover svg circle{fill:var(--primary-gold)}
.ques_item {
    border: 1px solid rgba(11, 31, 101, 0.08); cursor: pointer;
    padding: 1em;
    margin-bottom: 0.5em;
    border-radius: 10px;
    transition: var(--transition);
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between; position: relative;
}
.btns_grp{ display: flex; justify-content: space-between;}
.btns_grp button{ margin-top: 0; width: auto; border: none;}
.ques_price{ background-color: var(--primary-blue); font-size: 1em;}
.ques_list .selected{box-shadow: 0px 0px 0px 2px var(--primary-blue); background-color: white;}
.ques_list .selected strong{color: var(--primary-blue);}
.ques_item textarea,.ques_item .form-group{ width: 100%;}
.my_ques {
    border: 1px solid rgba(11, 31, 101, 0.08); flex-direction: column;
    
    margin-bottom: 2em;
    border-radius: 10px;
    transition: var(--transition);
    min-height: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between; position: relative; background-color: white;
}
.my_ques::after {
    content: "Question";
    position: absolute;
    background-color: white;
    border: 1px solid #dedede;
    font-size: 11px;
    top: -13px;
    padding: 3px 14px;
    border-radius: 20px;
    left: 20px;
}
.chk_event::after{
content: "Event" !important;
}
.mq_head {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    align-items: flex-start; padding: 25px 15px 5px 15px;
}
.mq_head h5 {
    display: inline-block;
    max-width: 500px;
}
.mq_body {
    width: 100%;
    margin-top: 0px;padding: 0px 15px;
}
.mq_status li span{ display: block; font-size: 1.10rem; margin-top: 8px;}
.mq_status li span.alert {
    font-size: 0.75rem;
    padding: 0.5em 0.75em;
    width: -webkit-max-content;
    width: max-content; margin-bottom: 0;
}
.mq_status {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.mq_status li{ flex-basis: 33%; margin: 0.75em 0;}
.mq_status li:nth-child(4){flex-basis: 100%;}

.my_ques .btns_grp {
    border-top: 1px solid rgba(11, 31, 101, 0.08);
    width: 100%;
    padding: 10px 15px;
    margin-top: 10px;
    justify-content: flex-end;
}
.btns_grp .gold_btn{ padding:0.5em 1em !important; font-size: 0.85rem; }

.user_head {
    display: flex;
    align-items: center;
}
.user_head button{
     font-weight:600;
	 color: var(--text-gray);	 
}
.user_head.home-page button{
 color:#fff;
}
.bg-white .user_head.home-page button{
 color: var(--text-gray);	 
}
.user_head .uh_img{ width: 30px; height: 30px; border-radius: 50%; overflow: hidden; border: 2px solid var(--text_light);}
.user_head .uh_img img{ width: 30px; height: 30px; border-radius: 50%; margin-right: 0px; object-fit: cover;}
.navbar-expand .navbar-nav .user_head .dropdown-menu{right: 0;}
.user_head .btn-check:focus + .btn, .btn:focus {
    outline: 0;
    box-shadow: none;
}
.dropdown-menu {    font-size: 0.85rem;}
.inner_header{ background-color: whitesmoke;}
.te_card {
    border: 1px solid rgba(11, 31, 101, 0.08);
    margin-bottom: 1.5em;
    border-radius: 10px;
    transition: var(--transition);
    min-height: 70px;
    position: relative;
    background-color: white;
}
.feedback_box .mq_head{border-bottom: 1px solid rgba(11, 31, 101, 0.08);padding: 25px 15px 15px 15px;}
.feedback_box .mq_body{ padding: 0px;}
.feedback_box .btns_grp {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    border-top: 1px solid rgba(11, 31, 101, 0.08);
}
.feedback_box .form-group{background-color: #f5f5f5;}
.feedback_box textarea.form-control{ background-color: #f5f5f5; border: none;}
.feedback_box .form_label {
    margin: 10px 15px 5px 15px;
    color: var(--primary-gold);
}
.feedback_box .forms_input_border:focus{ border: none !important;}
.dash_card {
    display: flex;
    min-height: 130px;
    align-items: center;
    justify-content: flex-start;
    padding: 30px;
}
.dc_icon {
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px; border-radius: 50%; margin-right: 30px; 
}
.dash_card:hover{ cursor: pointer; }
.shadow-sm:hover{box-shadow: 0 1rem 3rem rgba(0,0,0,.175) !important;}
.dci_1 .dc_icon,.dci_3 .dc_icon{ background-color: rgba(226, 179, 39,0.1);color: var(--primary-gold); transition: var(--transition);}
.dci_2 .dc_icon,.dci_4 .dc_icon{ background-color:rgba(11, 31, 101,0.1);color: var(--primary-blue); transition: var(--transition); }
.dc_info h2{ font-size: 2rem; margin-bottom: 0px; color:#212529;}

.dci_1:hover .dc_icon,.dci_3:hover .dc_icon{ background-color: rgb(226, 179, 39); color: white;}
.dci_2:hover .dc_icon,.dci_4:hover .dc_icon{ background-color:rgba(11, 31, 101,1);color: white }
 
.dci_1 small,.dci_3 small{ color: var(--primary-gold); font-weight:600;}
.dci_2 small,.dci_4 small{ color: var(--primary-blue); font-weight:600;}
.dc_info h2 span{ font-size: 13px; margin-right: 5px;}
.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: transparent;
    isolation: isolate; 
}
.nav-tabs .nav-link {
    margin-bottom: -1px;
    background: 0 0;
    border: 3px solid transparent;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1rem 1.75rem;color: var(--text_light);
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: var(--primary-dark);
    background-color: #fff;
    border-color: #fff #fff var(--primary-gold);
}
.te_main_lpage p.foot_p{color: white; margin: 0.5rem 0;}
.reviewBox {
    width: 100%;
    max-width: 470px;
    background-color: white;
    padding: 25px;
    border-radius: 10px;
    border-bottom: 1px solid rgba(11, 31, 101, 0.08);
    box-shadow: rgba(11, 31, 101, 0.05) 0px 3px 5px;
}
.rev_img {
    width: 50px;
    overflow: hidden;
    border-radius: 50%; box-shadow: rgba(11, 31, 101, 0.05) 0px 3px 5px; margin-right: 15px;
}
.rev_img img{
    width: 50px; height: 50%; object-fit: cover;
    border-radius: 50%;
}
.quote_icon {
    margin-bottom: 10px;
}
.te_main_lpage .reviewBox p{ font-style: italic; font-size: 14px; line-height: 24px;}
.rev_by {
    margin-top: 15px;
    display: flex;
    align-items: center;
}
.rev_by h5{color: var(--primary-blue); font-size: 16px; margin-bottom: 0;}
.rev_by h5 span{color: var(--text_light); display: block; font-size: 13px;}

.reviewCaro .owl-stage-outer{ padding: 30px 0 20px 0;}
.reviewCaro .owl-stage-outer .owl-item.active{opacity: 1;}
.reviewCaro .owl-stage-outer .owl-item{opacity: 0.5;}
.owl-carousel .owl-nav{ margin-top: 0;}
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next{width: 40px; height: 40px; border-radius: 20px; background-color: white;border: 1px solid rgba(11, 31, 101, 0.08);}

.ref-pgrm-content{ margin-top:15px;} 
.ref-pgrm-content .int-lbl{ font-weight:600; font-size:15px;}
.ref-pgrm-content .form-control { font-size:0.9rem}
.ref-pgrm-content button{ padding:0 60px; height:40px; letter-spacing:0; margin-top:15px;}
.ref-pgrm-content .inst{ background-color:#fff; border:1px solid #ddd; padding:20px;}
.ref-pgrm-content .inst h6{ font-size:0.98rem}
.ref-pgrm-content .inst p{ font-size:0.98rem; margin-bottom:0; line-height:30px; margin-left:10px;}

.dash_card.dash_ask_qus {
    background-color: var(--primary-gold);
    border-radius: 10px; transition: var(--transition);
}
.dash_card.dash_ask_qus a{
    display: flex;
    justify-content: space-evenly;
    color: white;
    align-items: center;width: 100%;
    font-size: 20px;
    font-weight: 600;
}
.dash_card.dash_ask_qus:hover{ background-color: var(--primary-blue);}
.dash_card.dash_ask_qus a span{ width: 40px; height: 40px; background-color: rgba(255,255,255,0.2); display: flex; justify-content: center; align-items: center; border-radius: 50%; visibility: hidden; transition: var(--transition); transform: translate(100px,0);}
.dash_card.dash_ask_qus:hover a span{ transform: translate(0,0); visibility: visible;}
.my_wallet {
    padding: 5px 15px;
    background-color: var(--primary-gold);
    border-radius: 20px;
    font-size: 13px;
    font-weight: 600;
    color: white;
    margin-right: 15px;
}
.my_wallet svg{
    margin-right: 5px;
}
.ref-pgrm .container{ max-width: 700px; margin: 25px auto;}
.ref_img{ max-width: 500px; margin: 10px auto;}
.reff_inst{ margin-top: 25px;}
.reff_inst h6{ font-size: 15px; line-height: 26px;}
.reff_inst ul{ padding-left: 15px; margin-top: 15px;}
.reff_inst ul li{ list-style-type: square; margin-bottom: 10px;}

.dropdown-item.active, .dropdown-item:active{background-color: var(--primary-blue) }

.braintree-show-methods [data-braintree-id='methods-label'], .braintree-show-methods [data-braintree-id='methods-edit'], .braintree-show-options [data-braintree-id='choose-a-way-to-pay']{ font-weight:600;}
.paymentPage .my_ques .btns_grp{justify-content: flex-start}
.paymentPage .btns_grp .gold_btn {
    padding: 0.5em 5em !important;
    font-size: 0.85rem;
}

.Toastify__toast{
	min-height: 55px;
	margin-bottom: 1rem;
}
.Toastify__toast--success{background:#568203;}

.psp .srtDesc{ text-align: center;line-height: 25px;font-size: 15px; padding-top:10px;}
/*.psp .forms_input{font-size:0.84rem}*/

.live_indi {
    width: 80px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid #c4c4c4;
    border-radius: 20px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 30px;
    margin-bottom: 10px;
}
.live_indi .spinner-grow {
    width: 1rem;
    height: 1rem;
}

.live_event_item .event_info {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}
.live_event_item .event_date{ width: auto; padding: .5rem 1rem;}
.live_event_item .event_title{ height: auto;}
.live_event_item .event_info li{ width: auto; flex-basis: 150px;}

.walNotice{border: 1px dashed #555;    padding: 10px 30px;}

.pro_filter {
    max-width: 700px;
    margin: 10px auto;
}
.pro_filter .form-select {
    padding: 0.55em .75em !important;
}
.pro_item_box {
    padding: 15px;
    border: 1px solid #eee;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    grid-gap: 20px;
    gap: 20px; background-color: white; margin-bottom: 10px; transition: all ease .3s; position: relative;
  }
.pro_item_box::before{ width: 5px; height: 30px; position: absolute; top: 15px; left: 0; content: ""; background-color: var(--primary-gold);}
.pro_item_box:hover::before{ background-color: var(--primary-blue);}
.pro_item_box:hover{ box-shadow: 0 7px 30px rgba(0,0,0,.11);}
.pib_img{ width: 40px; height: 40px; display: none; justify-content: center; align-items: center; background-color: var(--primary-gold); border-radius: 50%; color: white; }
.type_of{ font-size: 14px; font-weight: 600; background-color: #F1F4F8; padding: 5px 10px; border-radius: 10px; margin-bottom: 8px; display: inline-block;}
.pro_address{ margin-bottom: 0;}
.pro_address svg{ color: var(--primary-gold); margin-right: 5px;}
.pro_name{ color: var(--primary-blue);}

.become_pro{background-color:#f1f1f1;padding:15px;border-radius:10px;width:100%;max-width:700px;margin:20px auto;text-align:center;display:flex;align-items:center;grid-gap:15px;gap:15px;justify-content:space-evenly;flex-wrap:wrap;min-height:150px}
.become_pro p{margin-bottom:0}
